import { FC, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import Icon from '../icon/Icon';

import './splash.scss';

type TProps = {
  storageName: string;
  title: JSX.Element;
  toggleVisit: () => void;
};

const Splash: FC<TProps> = ({ storageName, title, toggleVisit }) => {
  const { t } = useTranslation();

  useEffect(() => {
    sessionStorage.setItem(storageName, 'true');
    setTimeout(() => {
      toggleVisit();
    }, 2100);
  }, []);

  return (
    <main className="splash">
      <div className="splash__title">{title}</div>
      <div className="splash__company">
        <span>{t('SPLASH.BY')}</span>
        <Icon height={3.2} name="LogoKinepolis" size={16.1} />
      </div>
    </main>
  );
};

export default Splash;
