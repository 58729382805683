import React, { useRef, FC, useEffect } from 'react';

import './carousel.scss';

type TCarouselProps = {
  activeTile: number;
  carouselCards: JSX.Element[];
};

export const Carousel: FC<TCarouselProps> = ({ activeTile, carouselCards }) => {
  const carouselRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const carouselElement = carouselRef.current;
    if (carouselElement && carouselElement.children.length > activeTile) {
      carouselElement?.children[activeTile]?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  }, [activeTile]);

  return (
    <>
      <div className="carousel-container">
        <div className="carousel-wrapper" ref={carouselRef}>
          {carouselCards}
        </div>
      </div>
    </>
  );
};

export default Carousel;
