import { FC, useEffect, useState } from 'react';

import classnames from 'classnames';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useToggle } from '../../../../_hooks';
import { Button, Icon } from '../../../../_shared';
import { formValidator } from '../../../../_utils/formValidation';
import { selectLanguageAndCountry } from '../../../../_utils/languageHelper';
import { BottomBar, ModalOpener } from '../../_components';
import QRScanner from '../../_components/qrScanner/QrScanner';
import { TOAST_POSITION, useSelectionContext } from '../../_context/SelectionContext';
import { SeatType, useScanningContext } from '../../_context/scanning/ScanningContext';
import { SCANNING_ACTIONS } from '../../_context/scanning/actions';
import { TUpdateSeat } from '../../_models';
import { useUpdateSeat } from '../../_queries';
import { extractUuid } from '../../_utils/scannerHelper';
import { MAX_SEATS } from '../singleSeat/success/SingleSeatSuccess';

import TypeChanger from './typeChanger/TypeChanger';

import './roomSetup.scss';

const RoomSetup: FC = () => {
  const availableTypes = Object.values(SeatType);
  const roomSetupDataSeatType = availableTypes.indexOf(JSON.parse(localStorage.getItem('roomSetupData'))?.seatType);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isIncrement, setIsIncrement] = useToggle(JSON.parse(localStorage.getItem('isIncrement')));
  const [isScanningPaused, setIsScanningPaused] = useState<boolean>(false);
  const [activeSeatType, setActiveSeatType] = useState<number>(roomSetupDataSeatType);
  const [invertColors, setInvertColors] = useToggle(false); // when false - white background, black qr
  const { roomSetupData, setRoomData } = useScanningContext();
  const { theatreData, room } = useSelectionContext();
  const { mutate: updateSeat } = useUpdateSeat({
    onSuccess: () => {
      setRoomData({ type: isIncrement ? SCANNING_ACTIONS.BUMP_ROOM_SETUP_SEAT : SCANNING_ACTIONS.LOWER_ROOM_SETUP_SEAT });
      navigate('scan-success');
    },
  });

  function initRoomSetup() {
    setRoomData({
      payload: { row: '1', seat: '1', seatType: SeatType.COSY },
      type: SCANNING_ACTIONS.UPDATE_SINGLE_SEAT_DATA,
    });
    setActiveSeatType(0);
  }

  useEffect(() => {
    if (!roomSetupData) initRoomSetup();
    if (!localStorage.getItem('isIncrement')) {
      localStorage.setItem('isIncrement', JSON.stringify(true));
      setIsIncrement(true);
    }
  }, []);

  function handleUpdate(value: string) {
    const { isoCountryCode } = selectLanguageAndCountry();
    const body: TUpdateSeat = {
      column: roomSetupData.seat,
      complexOperator: theatreData?.complexCode,
      countryCode: isoCountryCode,
      description: availableTypes[activeSeatType],
      row: roomSetupData.row,
      screenNumber: +room,
      seatGuid: extractUuid(value),
    };

    updateSeat(body);
  }

  const onFind = (value: string): void => {
    if (value) {
      setIsScanningPaused(true);
      handleUpdate(value);
      setTimeout(() => setIsScanningPaused(false), 500);
    }
  };

  const scan = debounce(onFind, 300);

  function submitRow(row: string) {
    const { isValid, message } = formValidator.rowValidation(row);
    if (isValid) {
      setRoomData({ payload: { row, seat: '1' }, type: SCANNING_ACTIONS.UPDATE_ROOM_SETUP_DATA });
      ModalOpener.instance.close();
    } else {
      toast.error(message, TOAST_POSITION);
    }
  }

  function submitSeat(seat: string) {
    const { isValid, message } = formValidator.seatValidation(+seat, MAX_SEATS);
    if (isValid) {
      setRoomData({ payload: { seat }, type: SCANNING_ACTIONS.UPDATE_ROOM_SETUP_DATA });
      ModalOpener.instance.close();
    } else {
      toast.error(message, TOAST_POSITION);
    }
  }

  function handleModal({
    label,
    needNumeric = false,
    submitModal,
  }: {
    label: string;
    needNumeric?: boolean;
    submitModal: (val: string) => void;
  }) {
    ModalOpener.instance.open({
      render: () => <BottomBar label={label} needNumeric={needNumeric} onSubmit={submitModal} />,
    });
  }

  function scanAllMappedSeats() {
    initRoomSetup();
    navigate('success');
  }

  return (
    <div className="room-setup">
      <div className="room-setup__header">
        <Button className={classnames({ 'is-dark': !invertColors })} onClick={setInvertColors} theme="ghost">
          {t('ADMIN.INVERT_COLORS')}
        </Button>
        <Button onClick={scanAllMappedSeats} theme="ghost">
          {t('ADMIN.SCANNING_MODE.ROOM_SETUP_SCAN.ALL_MAPPED')}
        </Button>
      </div>

      <div className="room-setup__content">
        <QRScanner invertColors={invertColors} onFind={scan} pauseScanning={isScanningPaused} />
      </div>

      <div className="room-setup__actions">
        <div className="room-setup__actions__items">
          <Button
            onClick={() =>
              handleModal({
                label: t('ADMIN.SCANNING_MODE.SINGLE_SEAT.CHOOSE_A_ROW'),
                needNumeric: true,
                submitModal: submitRow,
              })
            }
            theme="selection"
          >
            {t('ADMIN.SCANNING_MODE.ROW', { row: roomSetupData?.row })}
          </Button>
          <Button
            onClick={() =>
              handleModal({
                label: t('ADMIN.SCANNING_MODE.SINGLE_SEAT.CHOOSE_A_SEAT'),
                submitModal: submitSeat,
              })
            }
            theme="selection"
          >
            {t('ADMIN.SCANNING_MODE.SEAT', { seat: roomSetupData?.seat })}
          </Button>
          <span
            onClick={() => {
              setIsIncrement();
              localStorage.setItem('isIncrement', JSON.stringify(!isIncrement));
            }}
          >
            <Icon className={classnames('seat-up', { 'is-increment': isIncrement })} name="Right" size={5} />
          </span>
          <div className="seat-type-wrapper">
            <TypeChanger
              className="left"
              disabled={activeSeatType === 0}
              onClick={() => {
                setActiveSeatType(prevState => prevState - 1);
                setRoomData({
                  payload: { seatType: availableTypes[activeSeatType - 1] },
                  type: SCANNING_ACTIONS.UPDATE_ROOM_SETUP_DATA,
                });
              }}
            />
            <p>{t('ADMIN.SCANNING_MODE.SEAT_TYPE_COUNT', { seatType: availableTypes[activeSeatType] })}</p>
            <TypeChanger
              className="right"
              disabled={activeSeatType === availableTypes.length - 1}
              onClick={() => {
                setActiveSeatType(prevState => prevState + 1);
                setRoomData({
                  payload: { seatType: availableTypes[activeSeatType + 1] },
                  type: SCANNING_ACTIONS.UPDATE_ROOM_SETUP_DATA,
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomSetup;
