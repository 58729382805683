import { TSeatData, TUpdateType } from './ScanningContext';
import { SCANNING_ACTIONS } from './actions';

export function scanningReducer(state: TSeatData, action: { payload: TUpdateType; type: SCANNING_ACTIONS }) {
  switch (action.type) {
    case 'UPDATE_SINGLE_SEAT_DATA':
      return { ...state, ...action.payload };
    case 'UPDATE_ROOM_SETUP_DATA':
      return {
        ...state,
        ...action.payload,
      };
    case 'BUMP_ROOM_SETUP_SEAT':
      return {
        ...state,
        seat: `${+state.seat + 1}`,
      };
    case 'LOWER_ROOM_SETUP_SEAT':
      return {
        ...state,
        seat: `${+state.seat - 1}`,
      };
    default:
      return state;
  }
}
