import { FC, ReactNode, useCallback, useEffect } from 'react';

import { ModalOpener } from './modalOpener/ModalOpener';

import './modal.scss';

type TProps = {
  children: ReactNode;
  className?: string;
};

const Modal: FC<TProps> = ({ children, className }) => {
  const handleKeyDown = useCallback(event => {
    if (event.key === 'Escape') ModalOpener.instance.close();
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <div className={`modal ${className}`}>
      <div className={`modal__content ${className}__content`}>{children}</div>
    </div>
  );
};

export default Modal;
