import { FC, useLayoutEffect } from 'react';

import { Route, Routes, useLocation } from 'react-router-dom';

import { ModalOpener } from '../_components';

import ScanningModeSelection from './scanningMode/ScanningModeSelection';
import Selection from './selection/Selection';
import TheatreSelection from './theatre/TheatreSelection';

const SelectionRoutes: FC = () => {
  const { pathname } = useLocation();

  useLayoutEffect(() => ModalOpener.instance.closeAll(), [pathname]);

  return (
    <Routes>
      <Route element={<Selection />} path="" />
      <Route element={<TheatreSelection />} path="theatre-selection" />
      <Route element={<ScanningModeSelection />} path="scanning-mode" />
    </Routes>
  );
};

export default SelectionRoutes;
