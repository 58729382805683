import React, { BaseSyntheticEvent, useCallback } from 'react';

import classnames from 'classnames';
import { Link, NavLink } from 'react-router-dom';

import { isExternalLink, isPlatformLink } from '../../_utils/linkHelpers';
import Icon, { TIconType } from '../icon/Icon';
import Spinner from '../spinner/Spinner';

import './button.scss';

const ANCHOR = 'A';
type TButtonVariant = 'primary' | 'secondary' | 'outline' | 'ghost' | 'tile' | 'plain-link' | 'wrapper' | 'selection';
export type TActionType = string | ((event?: BaseSyntheticEvent) => void);
export type TProps = {
  autoFocus?: boolean;
  children?: React.ReactNode | undefined;
  className?: string;
  disabled?: boolean;
  form?: string;
  hideLabel?: boolean;
  href?: string;
  icon?: TIconType;
  iconHeight?: number;
  iconPosition?: 'left' | 'right';
  iconSize?: number;
  id?: string;
  isNavLink?: boolean;
  loading?: boolean;
  notification?: number;
  onClick?: (event?: BaseSyntheticEvent) => void;
  stopPropagation?: boolean;
  theme?: TButtonVariant;
  type?: 'button' | 'submit' | 'reset';
};

export type TForwardRef = React.ReactNode;

const Button = React.forwardRef<TForwardRef, TProps>(
  (
    {
      hideLabel,
      children,
      className = '',
      disabled,
      href,
      icon,
      iconSize = 1.8,
      iconHeight,
      iconPosition,
      isNavLink,
      loading,
      notification,
      onClick,
      theme = 'primary',
      type = 'button',
      stopPropagation = false,
      ...otherProps
    },
    ref,
  ) => {
    const cssClasses = classnames(className, `button--${theme}`, {
      button: theme !== 'wrapper',
      'button--icon': icon && hideLabel,
      'button--icon-reverse': iconPosition === 'right',
      'button--loading': loading,
      'button--with-icon': icon,
      'button--with-text': !hideLabel,
    });
    const notifications = notification >= 100 ? '99+' : notification;
    const Type: React.ElementType =
      href && !disabled ? (isExternalLink(href) || isPlatformLink(href) ? 'a' : isNavLink ? NavLink : Link) : 'button';
    let actionProp: Record<string, unknown> = {};

    const callback = useCallback(
      event => {
        // Do not perform custom actions when the user has a modifier key pressed on an anchor
        if (
          (event.metaKey || event.shiftKey || event.altKey || event.ctrlKey || event.button !== 0) &&
          event.currentTarget.nodeName.toUpperCase() === ANCHOR
        )
          return;

        event.preventDefault();
        if (stopPropagation) {
          event.stopPropagation();
        }

        onClick?.(event);
      },
      [onClick, stopPropagation],
    );

    if (href && !disabled) {
      if (isExternalLink(href) || isPlatformLink(href)) {
        actionProp = {
          'aria-disabled': disabled,
          href: disabled ? '#' : href,
          onClick: onClick ? callback : stopPropagation ? event => event.stopPropagation() : undefined,
          rel: isExternalLink(href) ? 'noopener noreferrer' : undefined,
          target: isExternalLink(href) ? '_blank' : undefined,
        };
      } else {
        actionProp = { 'aria-disabled': disabled, onClick, to: disabled ? '#' : href };
      }
    } else {
      actionProp = { disabled, onClick, type };
    }

    const inlineSpinner = loading ? (
      <div className="spinner-wrapper">
        <Spinner theme="inversed" />
      </div>
    ) : null;
    const inlineIcon = icon ? <Icon height={iconHeight} name={icon} size={iconSize} /> : undefined;

    return (
      <Type {...otherProps} {...actionProp} className={cssClasses} ref={ref} to={actionProp?.to}>
        {notification && inlineIcon && !hideLabel ? (
          <span className="button__notificationWrapper">
            {inlineIcon}
            <span className="button__notification">{notifications}</span>
          </span>
        ) : (
          inlineIcon
        )}
        {hideLabel ? (
          <span className="visually-hidden">{children}</span>
        ) : typeof children === 'string' ? (
          <span className="button__label">{children}</span>
        ) : (
          children
        )}
        {inlineSpinner}
        {notifications && (!icon || hideLabel) ? <span className="button__notification">{notifications}</span> : undefined}
      </Type>
    );
  },
);

export default Button as React.FC<TProps>;
