import { CSSProperties, FC } from 'react';

import classnames from 'classnames';

import * as ICONS from '../../_assets/svg';

import './icon.scss';

export type TIconType = keyof typeof ICONS;

type TProps = {
  className?: string;
  height?: number;
  label?: string;
  name: TIconType;
  size?: number;
};

interface Style extends CSSProperties {
  '--height'?: string;
  '--width'?: string;
}

const Icon: FC<TProps> = ({ size = 2, height, name, className = '', label, ...otherProps }) => {
  const Svg = ICONS[name];
  if (!Svg) return null;

  const sizeObject: Style = size ? { '--height': `${height ?? size}rem`, '--width': `${size}rem` } : {};

  return (
    <span {...otherProps} className={classnames('icon', className)} style={sizeObject}>
      <Svg aria-hidden="true" focusable="false" />
      {label ? <span className="visually-hidden">{label}</span> : undefined}
    </span>
  );
};

export default Icon;
