import { FC, useEffect, useLayoutEffect } from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { useToggle } from '../../_hooks';
import { Splash } from '../../_shared';
import LanguagePicker from '../../_translations/languagePicker/LanguagePicker';

import { SeatInfoContextProvider } from './_context/SeatInfoContext';
import Benefits from './_pages/benefits/Benefits';
import GuestLogin from './_pages/guestLogin/GuestLogin';
import Home from './_pages/home/Home';
import Settings from './_pages/settings/Settings';

const AppRoutes: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [isFirstVisitApp, toggleIsFirstVisitApp] = useToggle(sessionStorage.getItem('firstVisitApp') !== 'true');
  const regex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/g;
  const guid = pathname.split('/')[1]; // guid has to be the first item of the pathname

  useLayoutEffect(() => {
    window.scroll(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (regex.test(guid)) {
      localStorage.setItem('guid', guid);
    }
  }, []);

  if (isFirstVisitApp)
    return <Splash storageName="firstVisitApp" title={<h1>{t('SPLASH.TITLE')}</h1>} toggleVisit={toggleIsFirstVisitApp} />;

  return (
    <>
      <Helmet>
        <title>{`${t('SHARED.KINEPOLIS')} | ${t('SPLASH.TITLE')}`}</title>
      </Helmet>
      <SeatInfoContextProvider>
        <Routes>
          <Route element={<Home />} path="" />
          <Route element={<Benefits />} path="benefits" />
          <Route element={<Settings />} path="settings" />
          <Route element={<LanguagePicker />} path="languages" />
          <Route element={<GuestLogin />} path="guest" />
          <Route element={<Navigate to="" />} path="*" />
        </Routes>
      </SeatInfoContextProvider>
    </>
  );
};

export default AppRoutes;
