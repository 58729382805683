import { createContext, FC, ReactNode, useContext, useReducer } from 'react';

import { SCANNING_ACTIONS } from './actions';
import { scanningReducer } from './reducer';

export enum SeatType {
  COSY = 'Cosy',
  COSY_PLUS = 'Cosy+',
  RECLINER = 'Recliner',
  STANDARD = 'Standard',
  VIP = 'VIP',
}

export type TUpdateType = {
  row?: string;
  seat?: string;
  seatType?: string;
  uuid?: string;
};

export type TSeatData = { row: string; seat: string; seatType: string; uuid: string };
export type TPayloadSeatData = { payload?: TUpdateType; type: SCANNING_ACTIONS };

type TScanningContext = {
  roomSetupData: TSeatData;
  setRoomData: (payload: TPayloadSeatData) => void;
  setSeatData: (payload: TPayloadSeatData) => void;
  singleSeatData: TSeatData;
};

export const ScanningContext = createContext<TScanningContext>({
  roomSetupData: { row: '', seat: '', seatType: '', uuid: '' },
  setRoomData: () => {},
  setSeatData: () => {},
  singleSeatData: { row: '', seat: '', seatType: '', uuid: '' },
});

export const useScanningContext = () => useContext(ScanningContext);

type TProps = {
  children: ReactNode;
};

export const ScanningContextProvider: FC<TProps> = ({ children }) => {
  const initSingleSeatData = JSON.parse(localStorage.getItem('singleSeatData'));
  const initRoomSetupData = JSON.parse(localStorage.getItem('roomSetupData'));
  const [singleSeatData, setSingleSeatData] = useReducer(scanningReducer, initSingleSeatData);
  const [roomSetupData, setRoomSetupData] = useReducer(scanningReducer, initRoomSetupData);

  function setSeatData({ type, payload }: TPayloadSeatData) {
    setSingleSeatData({ payload, type });
    localStorage.setItem('singleSeatData', JSON.stringify({ ...singleSeatData, ...payload }));
  }

  function setRoomData({ type, payload }: TPayloadSeatData) {
    setRoomSetupData({ payload, type });
    localStorage.setItem('roomSetupData', JSON.stringify({ ...roomSetupData, ...payload }));
  }

  return (
    <ScanningContext.Provider
      value={{
        roomSetupData,
        setRoomData,
        setSeatData,
        singleSeatData,
      }}
    >
      {children}
    </ScanningContext.Provider>
  );
};
