import { useQuery } from 'react-query';

import { HttpClient, TApiError } from '../../../_http';
import { getLocale } from '../../../_translations';
import i18n from '../../../_translations/i18n';
import { currentCountry } from '../../../_utils/country';
import { selectLanguageAndCountry } from '../../../_utils/languageHelper';
import { TMovieInfo } from '../_models';

export function getCurrentMovie(seatId: string): Promise<TMovieInfo> {
  const language = i18n.language;
  const { isoCountryCode } = selectLanguageAndCountry();
  const country: string = isoCountryCode || currentCountry;

  return HttpClient.get<TMovieInfo>(`CurrentMovie/${country}/${getLocale(language).split('-')[0]?.toUpperCase()}/${seatId}`);
}

export function useGetCurrentMovie(seatId: string) {
  return useQuery<TMovieInfo, TApiError>(['current-movie', seatId], () => getCurrentMovie(seatId));
}
