import React, { FC, useState } from 'react';

import * as ICONS from '../../../../_assets/svg';
import Image from '../../../../_shared/Image/Image';
import { DATE_STRING_FORMAT, formatISOString } from '../../../../_utils/dateHelpers';
import { env } from '../../../../env';
import { TTrailer } from '../../_models';

import './upcomingMovieCard.scss';

type TMovieCardProps = {
  handleVoteClick: (id: string) => void;
  isLiked: boolean;
  movie: TTrailer;
};

const UpcomingMovieCard: FC<TMovieCardProps> = ({ movie }) => {
  const [posterAvailable, setPosterAvailable] = useState<boolean>(movie?.posterUrl !== null && movie?.posterUrl !== undefined);
  const NoPosterSvg = ICONS['LogoStar'];

  return (
    <div className="upcoming-movies__card" key={movie.movieId}>
      <div className="image-container">
        <span className="release-date">{movie?.releaseDate && formatISOString(movie?.releaseDate, DATE_STRING_FORMAT)}</span>
        {movie.isEvent && <div className="event-banner">{movie.eventTitle}</div>}
        {posterAvailable ? (
          <Image
            alt={movie?.movieTitle}
            className={(movie.isEvent || movie?.releaseDate) && 'no-rounding'}
            lazyLoading
            onError={() => setPosterAvailable(() => false)}
            src={posterAvailable && `${env.REACT_APP_POSTER_CDN}images${movie?.posterUrl}?impolicy=poster`}
          />
        ) : (
          <div className="no-poster">
            <NoPosterSvg className="no-poster__svg" />
          </div>
        )}
        {/* <button className={isLiked ? 'button--active' : ''} onClick={() => handleVoteClick(movie.movieId)} /> */}
        {/* Turned off till voring is allowed for non trailers */}
      </div>
      <div className="movie-title">{movie?.movieTitle}</div>
    </div>
  );
};

export default UpcomingMovieCard;
