import { ChangeEvent, FC, ReactNode } from 'react';

import { useId } from '@mantine/hooks';
import classnames from 'classnames';

import { useInputError } from '../../../_hooks';
import InputWrapper, { TInputWrapperProps } from '../InputWrapper';

import './inputField.scss';

type TInputFieldProps = TInputWrapperProps & {
  autoComplete?: string;
  autoFocus?: boolean;
  icon?: ReactNode;
  normalize?: (value: string) => string | number;
  onChange: (value: string | number, name: string) => void;
  pattern?: string;
  placeholder?: string;
  type?: 'email' | 'number' | 'password' | 'text' | 'tel';
  value?: string;
};

const InputField: FC<TInputFieldProps> = ({
  autoComplete,
  autoFocus,
  icon,
  normalize,
  onChange,
  placeholder,
  type = 'text',
  value,
  pattern,
  ...wrapperProps
}) => {
  const { disabled, validation, name } = wrapperProps;
  const { setDirty, showError } = useInputError(validation);
  const uuid = useId(wrapperProps.id);

  return (
    <InputWrapper id={uuid} {...wrapperProps} showError={showError}>
      <input
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        className={classnames('input', { error: showError })}
        disabled={disabled}
        id={uuid}
        name={name}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const { value } = event.target;
          let normalizedValue = type === 'number' ? parseFloat(value) : value;
          if (normalize) normalizedValue = normalize(value);
          onChange(normalizedValue, name);
          setDirty();
        }}
        pattern={pattern}
        placeholder={placeholder}
        type={type}
        value={value}
      />
      {icon}
    </InputWrapper>
  );
};

export default InputField;
