import { FC, useEffect, useState } from 'react';

import { useOidc, useOidcUser } from '@axa-fr/react-oidc';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { EffectCreative } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Button } from '../../../../_shared';
import Image from '../../../../_shared/Image/Image';
import { currentCountry } from '../../../../_utils/country';
import { DATE_STRING_FORMAT, formatISOString } from '../../../../_utils/dateHelpers';
import { env } from '../../../../env';
import { BottomSheet } from '../../_components';
import { useSeatInfoContext } from '../../_context/SeatInfoContext';
import { SessionVoteBody } from '../../_hooks/useTrailerVote';
import { TVoteBody } from '../../_models';
import { useVote } from '../../_queries';
import { VOTE_AFTER_INTERVAL, VOTE_BEFORE_INTERVAL } from '../../_utills/showTimeHelpers';

import './trailerVoting.scss';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

type TProps = {
  /**
   * Set the index of the carousel with this. Only needed in the upcoming movies component
   */
  selectUpcomingMoviesCarouselCard?: () => void;
};

const TrailerVoting: FC<TProps> = ({ selectUpcomingMoviesCarouselCard }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(JSON.parse(sessionStorage.getItem('activeIndex')) || 0);
  const [likes, setLikes] = useState<string[]>((sessionStorage.getItem('likes') || '').split(','));
  const { login, isAuthenticated } = useOidc();
  const { oidcUser } = useOidcUser();
  const { seatId, movieInfo } = useSeatInfoContext();
  const { mutate: vote, isError } = useVote();
  const [isBottomSheetDismissed, setIsBottomSheetDismissed] = useState(true);

  const activeTrailer = movieInfo?.trailers?.[activeIndex];
  const guestEmail = sessionStorage.getItem('guest');
  const creativeEffectDistance = window.innerWidth > 600 ? window.innerWidth / 12 : window.innerWidth / 5;
  const isLiked = likes.includes(activeTrailer?.movieId);
  const hasUserCreds = (oidcUser && oidcUser.name) || guestEmail;
  const updatedLikes = isLiked ? likes.filter(value => value !== activeTrailer?.movieId) : [...likes, activeTrailer?.movieId];
  const trailersPresent = movieInfo?.trailers?.length > 0;

  function setTrailerLikes(updatedLikes: string[]) {
    setLikes(updatedLikes);
    sessionStorage.setItem('likes', updatedLikes.join(','));
  }

  useEffect(() => {
    const sessionVote: SessionVoteBody = JSON.parse(sessionStorage.getItem('voteBody'));
    if (sessionVote) {
      trailerVote(sessionVote);
      sessionStorage.removeItem('voteBody');
    }
  }, []);

  function trailerVote(overrides?: Partial<TVoteBody>) {
    if (hasUserCreds) {
      const body: TVoteBody = {
        countryCode: currentCountry,
        email: oidcUser?.name || guestEmail,
        guestUser: !!guestEmail && !oidcUser?.name,
        language: language.toUpperCase() !== 'NL' && language.toUpperCase() !== 'FR' ? 'NL' : language.toUpperCase(),
        like: !isLiked,
        seatId,
        trailerMovieId: movieInfo?.trailers?.[activeIndex]?.movieId,
        ...overrides,
      };
      vote(body);

      if (!isError) setTrailerLikes(updatedLikes);
    }
  }

  function handleVoteClick() {
    if (!guestEmail && !isAuthenticated) {
      const body: SessionVoteBody = {
        seatId,
        trailerMovieId: activeTrailer?.movieId,
      };

      setIsBottomSheetDismissed(false);
      sessionStorage.setItem('voteBody', JSON.stringify(body));
      setLikes([...likes, body.trailerMovieId]);
    } else {
      trailerVote();
    }
  }

  return (
    <main className={'trailer-voting'}>
      <h1>{trailersPresent ? t('TRAILER_VOTING.TITLE') : t('HOME.TRAILER_VOTING_BLOCKED_TITLE')}</h1>
      <p>
        {trailersPresent
          ? t('TRAILER_VOTING.DESCRIPTION')
          : t('HOME.TRAILER_VOTING_BLOCKED_TEXT', { amountAfter: VOTE_AFTER_INTERVAL, amountBefore: VOTE_BEFORE_INTERVAL })}
      </p>

      {trailersPresent ? (
        <section>
          <Image
            alt="trailer-poster"
            className="trailer-voting__effect"
            lazyLoading
            src={`${env.REACT_APP_POSTER_CDN}images${activeTrailer?.posterUrl}?impolicy=poster`}
          />
          <Swiper
            creativeEffect={{
              next: {
                opacity: 0.5,
                rotate: [0, 0, 4],
                shadow: false,
                translate: [`${creativeEffectDistance}%`, 0, 0],
              },
              prev: {
                opacity: 0.5,
                rotate: [0, 0, -4],
                shadow: false,
                translate: [`-${creativeEffectDistance}%`, 0, 0],
              },
            }}
            effect="creative"
            grabCursor
            initialSlide={activeIndex}
            modules={[EffectCreative]}
            onActiveIndexChange={({ activeIndex }) => {
              setActiveIndex(activeIndex);
              sessionStorage.setItem('activeIndex', activeIndex.toString());
            }}
          >
            {movieInfo?.trailers?.map(trailer => (
              <SwiperSlide key={trailer.movieId}>
                <div className="trailer">
                  <button
                    className={classnames('trailer-vote', { 'trailer-vote--active': likes.includes(trailer?.movieId) })}
                    onClick={handleVoteClick}
                  >
                    <Image
                      alt={trailer.movieTitle}
                      className="trailer__image"
                      lazyLoading
                      src={`${env.REACT_APP_POSTER_CDN}images${trailer.posterUrl}?impolicy=poster`}
                    />
                    <span className="visually-hidden">{t('TRAILER_VOTING.LIKE')}</span>
                  </button>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="trailer-voting__description">
            <span>{movieInfo && activeTrailer ? activeTrailer?.movieTitle : null}</span>
            <span>{t('TRAILER_VOTING.IN_THEATRE')}</span>
            <span className="trailer-voting__description__date">
              {activeTrailer?.releaseDate && formatISOString(activeTrailer?.releaseDate, DATE_STRING_FORMAT)}
            </span>
          </div>
        </section>
      ) : (
        <>
          {/* Disable till Voting works for upcoming movies and not only for the trailers before a movie in the cinema */}

          {selectUpcomingMoviesCarouselCard && (
            <div className="no-trailers">
              <Button className="no-trailers__button" onClick={selectUpcomingMoviesCarouselCard}>
                {t('HOME.TRAILER_VOTING_BLOCKED_BUTTON_TEXT')}
              </Button>
            </div>
          )}
        </>
      )}
      <BottomSheet isVisible={!isBottomSheetDismissed} setIsBottomSheetDismissed={setIsBottomSheetDismissed}>
        <>
          <h2>{t('TRAILER_VOTING.BOTTOM_SHEET_TITLE')}</h2>
          <p>{t('TRAILER_VOTING.BOTTOM_SHEET_TEXT')}</p>
          <Button onClick={() => login()}>{t('SHARED.BUTTONS.LOGIN')}</Button>
          <Button onClick={() => login()} theme="ghost">
            {t('SHARED.BUTTONS.CREATE_ACCOUNT')}
          </Button>
          <Button href="/guest" theme="ghost">
            {t('SHARED.BUTTONS.CONTINUE_AS_GUEST')}
          </Button>
        </>
      </BottomSheet>
    </main>
  );
};

export default TrailerVoting;
