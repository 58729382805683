import { countryList } from './countryList';

const timeZoneToCountry = {};
const { countries, zones } = countryList;

Object.keys(zones).forEach(z => {
  timeZoneToCountry[z] = countries[zones[z].countries[0]].abbr;
});

function getCountryCodeFromUrl(): string {
  const hostname = window.location.hostname;
  const parts = hostname.split('.');

  if (hostname.endsWith('.co.uk')) {
    return 'UK';
  }

  return parts.length >= 1 ? parts[parts.length - 1].toUpperCase() : 'defaultCountryCode';
}

const urlCountryCode = getCountryCodeFromUrl();
export const currentCountry = urlCountryCode || timeZoneToCountry[Intl.DateTimeFormat().resolvedOptions().timeZone];
