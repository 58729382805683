import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { labelForLocale, languages } from '..';
import { Button, Icon } from '../../_shared';
import { selectLanguageAndCountry } from '../../_utils/languageHelper';

import './languagePicker.scss';

const LanguagePicker: FC = () => {
  const {
    t,
    i18n: { changeLanguage },
  } = useTranslation();

  const { translations } = selectLanguageAndCountry();

  return (
    <main className="language-picker">
      {(translations as languages[]).map(lng => (
        <Button href="/" key={lng} onClick={() => changeLanguage(lng)} theme="wrapper">
          <span>{t('LANGUAGE_PICKER.CHOOSE')}</span>
          <span>{t('LANGUAGE_PICKER.LANGUAGE', { lng: t(labelForLocale(lng)) })}</span>
          <Icon name="Right" size={2.4} />
        </Button>
      ))}
    </main>
  );
};

export default LanguagePicker;
