import { useQuery } from 'react-query';

import { HttpClient, TApiError } from '../../../../_http';
import { currentCountry } from '../../../../_utils/country';
import { Config } from '../../../../config';
import { TComplex } from '../_models';

export function getComplexes(): Promise<TComplex[]> {
  return HttpClient.get<TComplex[]>(`${Config.api.adminHost}Complex/${currentCountry}/FR/Cinema`);
}

export function useGetComplexes() {
  return useQuery<TComplex[], TApiError>(['getComplexes'], getComplexes);
}
