import { FC } from 'react';

import { Route, Routes } from 'react-router-dom';

import RoomSetup from './roomSetup/RoomSetup';
import RoomSetupScanSuccess from './roomSetup/success/RoomSetupScanSuccess';
import RoomSetupSuccess from './roomSetup/success/RoomSetupSuccess';
import SingleSeat from './singleSeat/SingleSeat';
import SingleSeatSeatType from './singleSeat/seatType/SingleSeatSeatType';
import SingleSeatSuccess from './singleSeat/success/SingleSeatSuccess';

const ScanningRoutes: FC = () => {
  const singleSeatPathname = 'single-seat';
  const roomSetupPathname = 'room-setup';

  return (
    <Routes>
      {/* Single seat routes */}
      <Route element={<SingleSeatSeatType />} path="seat-type" />
      <Route element={<SingleSeat />} path={singleSeatPathname} />
      <Route element={<SingleSeatSuccess />} path={`${singleSeatPathname}/success`} />

      {/* Room setup routes */}
      <Route element={<RoomSetup />} path={roomSetupPathname} />
      <Route element={<RoomSetupSuccess />} path={`${roomSetupPathname}/success`} />
      <Route element={<RoomSetupScanSuccess />} path={`${roomSetupPathname}/scan-success`} />
    </Routes>
  );
};

export default ScanningRoutes;
