import { useQuery } from 'react-query';

import { HttpClient, TApiError } from '../../../_http';
import { currentCountry } from '../../../_utils/country';
import { selectLanguageAndCountry } from '../../../_utils/languageHelper';
import { TSeatInfo } from '../_models';

export function getSeatInfo(seatId: string): Promise<TSeatInfo> {
  const { isoCountryCode } = selectLanguageAndCountry();
  const country = isoCountryCode || currentCountry;
  return HttpClient.get<TSeatInfo>(`Seat/${country}/${seatId}`);
}

export function useGetSeatInfo(seatId: string) {
  return useQuery<TSeatInfo, TApiError>(['seat-info', seatId], () => getSeatInfo(seatId));
}
