import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { HttpClient, TApiError } from '../../../_http';
import { env } from '../../../env';
import { TOAST_POSITION } from '../_context/SelectionContext';
import { TUpdateSeat } from '../_models';

export function updateSeat(body: TUpdateSeat): Promise<void> {
  return HttpClient.post<void>(`Seat?code=${env.REACT_APP_AD_CONNECTIVE}`, body);
}

export function useUpdateSeat(options: { onSuccess: () => void }) {
  return useMutation<void, TApiError, TUpdateSeat>('update-seat', updateSeat, {
    onError: e => {
      toast.error(e.message, TOAST_POSITION);
    },
    onSuccess: () => {
      options.onSuccess();
    },
    ...options,
  });
}
