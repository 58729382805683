import { createContext, FC, ReactNode, useContext } from 'react';

import { TMovieInfo, TSeatInfo } from '../_models';
import { useGetCurrentMovie, useGetSeatInfo } from '../_queries';

type TSeatInfoContext = {
  movieInfo: TMovieInfo;
  seatId: string;
  seatInfo: TSeatInfo;
};

export const SeatInfoContext = createContext<TSeatInfoContext>({
  movieInfo: null,
  seatId: '',
  seatInfo: null,
});

export const useSeatInfoContext = () => useContext(SeatInfoContext);

type TProps = {
  children: ReactNode;
};
export const SeatInfoContextProvider: FC<TProps> = ({ children }) => {
  const seatId = localStorage.getItem('guid');
  const { data: seatInfo } = useGetSeatInfo(seatId);
  const { data: movieInfo } = useGetCurrentMovie(seatId);

  return (
    <SeatInfoContext.Provider
      value={{
        movieInfo,
        seatId,
        seatInfo,
      }}
    >
      {children}
    </SeatInfoContext.Provider>
  );
};
