import { FC, ReactNode } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from '../../../../_shared/button/Button';

import './bottomSheet.scss';

type TProps = {
  children: ReactNode;
  isVisible: boolean;
  setIsBottomSheetDismissed: (value: boolean) => void;
};

const BottomSheet: FC<TProps> = ({ children, isVisible, setIsBottomSheetDismissed }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('bottom-sheet', { 'bottom-sheet--active': isVisible })}>
      <Button className="bottom-sheet__dimmer" onClick={() => setIsBottomSheetDismissed(true)} theme="wrapper" />
      <div className="bottom-sheet__sheet">
        <Button
          className="bottom-sheet__sheet__close"
          hideLabel
          icon="Close"
          iconSize={2.4}
          onClick={() => setIsBottomSheetDismissed(true)}
          theme="wrapper"
        >
          {t('SHARED.BUTTONS.CLOSE')}
        </Button>
        <div className="bottom-sheet__sheet__content">{children}</div>
      </div>
    </div>
  );
};

export default BottomSheet;
