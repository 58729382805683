import { languages } from '../_translations';
import i18n from '../_translations/i18n';

type CountrySpecification = {
  countryCompanyCode: string;
  countryName: string;
  isoCountryCode: string;
  isoCurrencyCode: string;
  languageCodes: string[];
  translations: string[];
};

const DEFAULT_CONFIG_COUNTRY = 'be';
const DEFAULT_COUNTRY = 'BE'; // Is not the users country but the country from KP
const DEFAULT_LANGUAGE = 'nl';

const countrySpecifications: Record<string, CountrySpecification> = {
  be: {
    countryCompanyCode: 'BE',
    countryName: 'BELGIUM',
    isoCountryCode: 'BE',
    isoCurrencyCode: 'EUR',
    languageCodes: ['NL', 'FR'],
    translations: [languages.DutchBe, languages.FrenchBe],
  },
  ch: {
    countryCompanyCode: 'CH',
    countryName: 'SWITZERLAND',
    isoCountryCode: 'CH',
    isoCurrencyCode: 'EUR',
    languageCodes: ['DE'],
    translations: [languages.German],
  },
  en: {
    countryCompanyCode: 'EN',
    countryName: 'UK',
    isoCountryCode: 'EN',
    isoCurrencyCode: 'EUR',
    languageCodes: ['EN'],
    translations: [languages.English],
  },
  es: {
    countryCompanyCode: 'ES',
    countryName: 'SPAIN',
    isoCountryCode: 'ES',
    isoCurrencyCode: 'EUR',
    languageCodes: ['ES', 'CA'],
    translations: [languages.Spanish, languages.Catalan],
  },
  fr: {
    countryCompanyCode: 'FR',
    countryName: 'FRANCE',
    isoCountryCode: 'FR',
    isoCurrencyCode: 'EUR',
    languageCodes: ['FR'],
    translations: [languages.FrenchFr],
  },
  lu: {
    countryCompanyCode: 'LU',
    countryName: 'LUXEMBOURG',
    isoCountryCode: 'LU',
    isoCurrencyCode: 'EUR',
    languageCodes: ['FR', 'EN'],
    translations: [languages.FrenchLu, languages.English],
  },
  nl: {
    countryCompanyCode: 'NL',
    countryName: 'NETHERLANDS',
    isoCountryCode: 'NL',
    isoCurrencyCode: 'EUR',
    languageCodes: ['NL'],
    translations: [languages.DutchNl],
  },
};

function selectCountryConfig(): CountrySpecification {
  const storeUrl = window.location.host;
  const possibleDomainExtensions = Object.keys(countrySpecifications).join('|');
  const regexpSize = new RegExp(`kinepolis.(${possibleDomainExtensions})`);
  const [, extension] = storeUrl.match(regexpSize) || [];
  if (extension) return countrySpecifications[extension];
  return countrySpecifications[DEFAULT_CONFIG_COUNTRY];
}

export function selectLanguageAndCountry(): { isoCountryCode: string; possibleLanguage: string; translations: string[] } {
  const { isoCountryCode: isoCode, translations } = selectCountryConfig();
  const language = i18n?.language ? i18n?.language : translations[0] || DEFAULT_LANGUAGE;
  const possibleLanguage = translations.find(l => l === language) || DEFAULT_LANGUAGE;
  const isoCountryCode = isoCode || DEFAULT_COUNTRY;
  if (!i18n.isInitialized) i18n.init({ lng: possibleLanguage });
  return { isoCountryCode, possibleLanguage, translations };
}
