import { FC, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useForm, { TFormValidationErrors } from '../../../../_hooks/useForm';
import { Button, Checkbox, InputField } from '../../../../_shared';
import { formValidator } from '../../../../_utils/formValidation';

import './guestLogin.scss';

const GuestLogin: FC = () => {
  const [acceptedTermsAndConditions, setTermsAndConditions] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  function submitForm() {
    sessionStorage.setItem('guest', values.email);
    navigate('/');
  }

  function validateForm(values: { email: string }): TFormValidationErrors<{ email: string }> {
    return {
      email: formValidator.email(values.email),
    };
  }

  const { submit, values, setAttribute, validationErrors } = useForm({
    initialForm: { email: '' },
    submitForm,
    validateForm,
  });

  return (
    <main className="guest-login">
      <form onSubmit={submit}>
        <div className="guest-login__content">
          <h1>{t('GUEST_LOGIN.TITLE')}</h1>
          <InputField
            label={t('GUEST_LOGIN.EMAIL')}
            name="email"
            onChange={setAttribute}
            required
            validation={validationErrors.email}
            value={values.email}
          />
          <Checkbox
            checked={acceptedTermsAndConditions}
            name="termsAndConditions"
            onChange={checked => setTermsAndConditions(checked)}
            required
          >
            <Trans
              components={[
                <a
                  href={t('GUEST_LOGIN.PRIVACY_POLICY_LINK')}
                  key={t('GUEST_LOGIN.PRIVACY_POLICY_LINK')}
                  rel="noopener noreferrer"
                  target="_blank"
                />,
              ]}
              i18nKey="GUEST_LOGIN.ACCEPT_PRIVACY_POLICY"
            />
          </Checkbox>
        </div>
        <div className="guest-login__actions">
          <Button href="/" theme="ghost">
            {t('SHARED.BUTTONS.CANCEL')}
          </Button>
          <Button disabled={!values.email || !acceptedTermsAndConditions} type="submit">
            {t('SHARED.BUTTONS.CONFIRM')}
          </Button>
        </div>
      </form>
    </main>
  );
};

export default GuestLogin;
