import { FC, ReactNode } from 'react';

import classnames from 'classnames';

import './errorMessage.scss';

type Props = {
  children: ReactNode;
  className?: string;
  isVisible: boolean;
};

const ErrorMessage: FC<Props> = ({ className = '', children, isVisible }) => {
  if (isVisible && !!children)
    return (
      <div className={classnames('error-message', className)}>
        <span>{children}</span>
      </div>
    );
  return null;
};

export default ErrorMessage;
