import { FC, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { Icon } from '../../../../../_shared';

import './roomSetupSuccess.scss';

const ROOM_SETUP_TIMEOUT = 1000;

const RoomSetupScanSuccess: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => navigate('../room-setup'), ROOM_SETUP_TIMEOUT);
  }, []);

  return (
    <div className="room-setup-success">
      <Icon name="Success" size={25} />
    </div>
  );
};

export default RoomSetupScanSuccess;
