import React, { FC, useState } from 'react';

import { useOidc, useOidcUser } from '@axa-fr/react-oidc';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../_shared';
import { currentCountry } from '../../../../_utils/country';
import { BottomSheet } from '../../_components';
import { useSeatInfoContext } from '../../_context/SeatInfoContext';
import { SessionVoteBody } from '../../_hooks/useTrailerVote';
import { TTrailer, TVoteBody } from '../../_models';
import { useVote } from '../../_queries';

import ScrollToTop from './../../../../_shared/scrollToTop/ScrollToTop';
import UpcomingMovieCard from './UpcomingMovieCard';
import { TFilmResponse, useGetUpComingMovies } from './queries/useGetUpcomingMovies';

import './upcomingMovies.scss';

const UpcomingMovies: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { login, isAuthenticated } = useOidc();
  const { oidcUser } = useOidcUser();

  const [likes, setLikes] = useState<string[]>((sessionStorage.getItem('likes') || '').split(','));
  const [isBottomSheetDismissed, setIsBottomSheetDismissed] = useState(true);
  const { mutate: vote, isError: voteError } = useVote();

  const { data } = useGetUpComingMovies();
  const { seatId } = useSeatInfoContext();
  const guestEmail = sessionStorage.getItem('guest');
  const hasUserCreds = (oidcUser && oidcUser.name) || guestEmail;

  const filterUniqueMovies = (movies: TFilmResponse[]): TTrailer[] => {
    const uniqueMovies: TTrailer[] = [];
    const seenIds: Set<number> = new Set();
    if (movies?.length > 0) {
      for (const movie of movies) {
        if (!seenIds.has(movie?.corporateId)) {
          seenIds.add(movie?.corporateId);
          const trailer = {
            eventTitle: movie?.event?.isActive == true ? movie?.event?.name : '',
            isEvent: movie?.event?.isActive,
            movieId: movie?.id,
            movieTitle: movie?.title,
            posterUrl: movie?.images?.length > 0 ? movie?.images[0]?.url : undefined,
            releaseDate: movie?.releaseDate,
          } as TTrailer;
          uniqueMovies.push(trailer);
        }
      }
    } else {
      return [];
    }

    return uniqueMovies;
  };

  function setTrailerLikes(updatedLikes: string[]) {
    setLikes(updatedLikes);
    sessionStorage.setItem('likes', updatedLikes.join(','));
  }

  function trailerVote(movieId: string, overrides?: Partial<TVoteBody>) {
    const likesBeforeChange = [...likes];
    if (hasUserCreds) {
      const body: TVoteBody = {
        countryCode: currentCountry,
        email: oidcUser?.name || guestEmail,
        guestUser: !!guestEmail && !oidcUser?.name,
        language: language.toUpperCase() !== 'NL' && language.toUpperCase() !== 'FR' ? 'NL' : language.toUpperCase(),
        like: overrides?.like,
        seatId,
        trailerMovieId: movieId,
        ...overrides,
      };
      vote(body);

      if (!voteError) setTrailerLikes(likesBeforeChange);
    }
  }

  function handleVoteClick(movieId: string) {
    if (!guestEmail && !isAuthenticated) {
      const body: SessionVoteBody = {
        seatId,
        trailerMovieId: data.films.find(e => e.id == movieId).id,
      };

      setIsBottomSheetDismissed(false);
      sessionStorage.setItem('voteBody', JSON.stringify(body));
      setLikes([...likes, body.trailerMovieId]);
    } else {
      trailerVote(movieId);
    }
  }

  return (
    <main className="upcoming-movies-wrapper">
      <h1 className="upcoming-movies-wrapper__title">{t('HOME.UPCOMING_MOVIES')}</h1>
      <div className="upcoming-movies">
        {filterUniqueMovies(data?.films).map(movie => {
          const isLiked = likes.includes(movie.movieId);
          if (movie?.posterUrl == undefined) return;

          return <UpcomingMovieCard handleVoteClick={handleVoteClick} isLiked={isLiked} key={movie?.movieId} movie={movie} />;
        })}
      </div>
      <ScrollToTop />
      <BottomSheet isVisible={!isBottomSheetDismissed} setIsBottomSheetDismissed={setIsBottomSheetDismissed}>
        <>
          <h2>{t('TRAILER_VOTING.BOTTOM_SHEET_TITLE')}</h2>
          <p>{t('TRAILER_VOTING.BOTTOM_SHEET_TEXT')}</p>
          <Button onClick={() => login()}>{t('SHARED.BUTTONS.LOGIN')}</Button>
          <Button onClick={() => login()} theme="ghost">
            {t('SHARED.BUTTONS.CREATE_ACCOUNT')}
          </Button>
          <Button href="/guest" theme="ghost">
            {t('SHARED.BUTTONS.CONTINUE_AS_GUEST')}
          </Button>
        </>
      </BottomSheet>
    </main>
  );
};

export default UpcomingMovies;
