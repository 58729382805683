import React, { FC } from 'react';

type TImageProps = {
  alt?: string;
  className?: string;
  lazyLoading?: boolean;
  onError?: () => void;
  src: string;
};

const Image: FC<TImageProps> = ({ alt, className, lazyLoading = 'false', onError, src }) => {
  return <img alt={alt} className={className} loading={lazyLoading ? 'lazy' : 'eager'} onError={onError} src={src} />;
};

export default Image;
