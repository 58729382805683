import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useToggle } from '../../../../_hooks';
import { Button, Checkbox, InputField } from '../../../../_shared';
import Modal from '../modal/Modal';

import './bottomBar.scss';

type TProps = {
  label: string;
  needNumeric?: boolean;
  onSubmit: (val: string) => void;
};

const BottomBar: FC<TProps> = ({ label, needNumeric = false, onSubmit }) => {
  const [selectedValue, setValue] = useState<string>('');
  const [isTelType, setTelType] = useToggle(true);
  const { t } = useTranslation();

  return (
    <Modal className="bottom-modal">
      {needNumeric && (
        <div className="bottom-modal__content__toggle-wrapper">
          <Checkbox
            checked={isTelType}
            label={t('ADMIN.SCANNING_MODE.SINGLE_SEAT.NUMERIC_KEYBOARD')}
            name="numeric"
            onChange={setTelType}
            type="toggle"
          />
        </div>
      )}
      <div className="bottom-modal__content__submit-wrapper">
        <label htmlFor="inputfield-label">{label}</label>
        <div className="bottom-modal__content__submit-wrapper__inputfield-wrapper">
          <InputField
            autoFocus
            name="bottom-value"
            onChange={value => setValue(value as string)}
            type={isTelType ? 'tel' : 'text'}
            value={selectedValue}
          />
          <Button hideLabel icon="Right" iconSize={2.4} onClick={() => onSubmit(selectedValue)} />
        </div>
      </div>
    </Modal>
  );
};

export default BottomBar;
