import { createContext, FC, ReactNode, useContext, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { toast, ToastOptions } from 'react-toastify';

import { formValidator } from '../../../_utils/formValidation';
import { ModalOpener } from '../_components';
import { MAX_ROWS } from '../scanning/singleSeat/success/SingleSeatSuccess';

export const TOAST_POSITION = { position: 'top-center' } as ToastOptions;

type TTheatreData = { complexCode: string; theatre: string };

type TSelectionContext = {
  pickRoom: (room: string) => void;
  pickScanningMode: (theatre: string) => void;
  pickTheatre: ({ theatre, complexCode }: TTheatreData) => void;
  room: string;
  scanningMode: string;
  theatreData: TTheatreData;
};

export const SelectionContext = createContext<TSelectionContext>({
  pickRoom: () => {},
  pickScanningMode: () => {},
  pickTheatre: () => {},
  room: '',
  scanningMode: '',
  theatreData: undefined,
});

export const useSelectionContext = () => useContext(SelectionContext);

type TProps = {
  children: ReactNode;
};

export const SelectionContextProvider: FC<TProps> = ({ children }) => {
  const navigate = useNavigate();
  const [room, setRoom] = useState(localStorage.getItem('room'));
  const [theatreData, setTheatreData] = useState<TTheatreData>(JSON.parse(localStorage.getItem('theatreData')));
  const [scanningMode, setScanningMode] = useState(localStorage.getItem('scanningMode'));

  function pickRoom(room: string) {
    const { isValid, message } = formValidator.maxLength(+room, MAX_ROWS);
    if (isValid) {
      localStorage.setItem('room', room);
      setRoom(room);
      ModalOpener.instance.close();
    } else toast.error(message, TOAST_POSITION);
  }

  function pickTheatre({ theatre, complexCode }) {
    const data = { complexCode, theatre };
    localStorage.setItem('theatreData', JSON.stringify(data));
    setTheatreData(data);
    navigate(-1);
  }

  function pickScanningMode(scanningMode: string) {
    localStorage.setItem('scanningMode', scanningMode);
    setScanningMode(scanningMode);
    navigate(-1);
  }

  return (
    <SelectionContext.Provider
      value={{
        pickRoom,
        pickScanningMode,
        pickTheatre,
        room,
        scanningMode,
        theatreData,
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};
