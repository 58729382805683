const HTTP = 'http';
const HASH = '#';
const MAILTO = 'mailto:///';
const TEL = 'tel://';

export const isExternalLink = (link: string) => {
  return link.startsWith(HTTP);
};

export const isPlatformLink = (link: string) => {
  return link.startsWith(HASH) || link.startsWith(MAILTO) || link.startsWith(TEL);
};

export const getBaseUrl = () => {
  return `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
};
