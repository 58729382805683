import { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { Button, Icon } from '../../../../_shared';

import './backButton.scss';

type TProps = {
  title: string;
};

const BackButton: FC<TProps> = ({ title }) => {
  const navigate = useNavigate();

  return (
    <Button className="back-button" onClick={() => navigate(-1)} theme="ghost">
      <Icon name="Right" size={2.4} />
      <p>{title}</p>
    </Button>
  );
};

export default BackButton;
