import React, { FC, ReactNode } from 'react';

import classnames from 'classnames';

import { TValidatorResponse } from '../../_utils/formValidation';
import ErrorMessage from '../errorMessage/ErrorMessage';
import Icon, { TIconType } from '../icon/Icon';

import './inputWrapper.scss';
/**
 * This component serves as a wrapper around the specific input components. It contains some common input logic:
 *  - Show input label and icon
 *  - Show error message if needed
 *  - Show whether a field is required
 */

export type TInputWrapperProps = {
  className?: string;
  disabled?: boolean;
  id?: string;
  label?: string;
  labelIcon?: TIconType;
  name: string;
  required?: boolean;
  validation?: TValidatorResponse;
};

const InputWrapper: FC<TInputWrapperProps & { children: ReactNode; showError?: boolean }> = ({
  children,
  className = '',
  disabled,
  validation,
  label,
  labelIcon,
  id,
  required,
  showError,
}) => {
  return (
    <div className={classnames('input-wrapper', { disabled, error: showError, required }, className)}>
      {!!label && (
        <label className="input-wrapper-label" htmlFor={id}>
          {!!labelIcon && <Icon name={labelIcon} />}
          <span>{label}</span>
        </label>
      )}
      {children}
      <ErrorMessage isVisible={showError}>{validation?.message}</ErrorMessage>
    </div>
  );
};
export default InputWrapper;
