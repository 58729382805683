import { FC } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '../../../../_shared';
import BackButton from '../../_components/backButton/BackButton';
import { useSelectionContext } from '../../_context/SelectionContext';

import '../../_styles/_selection.scss';

enum ScanningModes {
  ROOM = 'Room',
  SINGLE_SEAT = 'Single_seat',
}

const ScanningModeSelection: FC = () => {
  const { i18n } = useTranslation();
  const { scanningMode, pickScanningMode } = useSelectionContext();

  return (
    <>
      <BackButton title={i18n.t('ADMIN.SCANNING_MODE.TITLE')} />
      <div className="selection">
        {Object.keys(ScanningModes).map(mode => {
          const isSelected = scanningMode === mode;
          const setupType = `ADMIN.SCANNING_MODE.${mode}_SETUP`;
          const setupTypeInfo = `ADMIN.SCANNING_MODE.${mode}_SETUP_INFO`;

          return (
            <div className={classnames('selection__wrapper', { 'is-selected': isSelected })} key={mode}>
              <Button onClick={() => pickScanningMode(mode)} theme="ghost">
                <label>{i18n.t(setupType)}</label>
                <p>{i18n.t(setupTypeInfo)}</p>
              </Button>
              {isSelected && <Icon name="Check" />}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ScanningModeSelection;
