import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, Icon } from '../../../../../_shared';
import { formValidator } from '../../../../../_utils/formValidation';
import { selectLanguageAndCountry } from '../../../../../_utils/languageHelper';
import { BottomBar, ModalOpener } from '../../../_components';
import { TOAST_POSITION, useSelectionContext } from '../../../_context/SelectionContext';
import { useScanningContext } from '../../../_context/scanning/ScanningContext';
import { SCANNING_ACTIONS } from '../../../_context/scanning/actions';
import { TSeatType, TUpdateSeat } from '../../../_models';
import { useUpdateSeat } from '../../../_queries';

import './singleSeatSuccess.scss';

export const MAX_ROWS = 99;
export const MAX_SEATS = 999;

const SingleSeatSuccess: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { singleSeatData, setSeatData } = useScanningContext();
  const { theatreData, room } = useSelectionContext();
  const { mutate: updateSeat } = useUpdateSeat({
    onSuccess: () => {
      toast.success('QR code mapped successfully', TOAST_POSITION);
      navigate('/admin');
    },
  });
  const hasNotEnoughSeatData = !singleSeatData.row || !singleSeatData.seat || !singleSeatData.seatType;

  function submitRow(row: string) {
    const { isValid, message } = formValidator.rowValidation(row);
    if (isValid) {
      setSeatData({ payload: { row }, type: SCANNING_ACTIONS.UPDATE_SINGLE_SEAT_DATA });
      ModalOpener.instance.close();
    } else {
      toast.error(message, TOAST_POSITION);
    }
  }

  function submitSeat(seat: string) {
    const { isValid, message } = formValidator.seatValidation(+seat, MAX_SEATS);
    if (isValid) {
      setSeatData({ payload: { seat }, type: SCANNING_ACTIONS.UPDATE_SINGLE_SEAT_DATA });
      ModalOpener.instance.close();
    } else {
      toast.error(message, TOAST_POSITION);
    }
  }

  const actions = [
    {
      onClick: () =>
        ModalOpener.instance.open({
          render: () => <BottomBar label={t('ADMIN.SCANNING_MODE.SINGLE_SEAT.CHOOSE_A_ROW')} needNumeric onSubmit={submitRow} />,
        }),
      title: singleSeatData?.row
        ? t('ADMIN.SCANNING_MODE.ROW', {
            row: singleSeatData.row,
          })
        : t('ADMIN.SCANNING_MODE.SINGLE_SEAT.CHOOSE_ROW'),
    },
    {
      onClick: () =>
        ModalOpener.instance.open({
          render: () => <BottomBar label={t('ADMIN.SCANNING_MODE.SINGLE_SEAT.CHOOSE_A_SEAT')} onSubmit={submitSeat} />,
        }),
      title: singleSeatData?.seat
        ? t('ADMIN.SCANNING_MODE.SEAT', { seat: singleSeatData.seat })
        : t('ADMIN.SCANNING_MODE.SINGLE_SEAT.CHOOSE_SEAT'),
    },
    {
      href: '../seat-type',
      title: singleSeatData?.seatType
        ? t('ADMIN.SCANNING_MODE.SEAT_TYPE_COUNT', { seatType: singleSeatData.seatType })
        : t('ADMIN.SCANNING_MODE.SEAT_TYPE'),
    },
  ];

  function confirmScannedData() {
    const { isoCountryCode } = selectLanguageAndCountry();
    const body: TUpdateSeat = {
      column: singleSeatData.seat,
      complexOperator: theatreData?.complexCode,
      countryCode: isoCountryCode,
      description: singleSeatData.seatType as TSeatType,
      row: singleSeatData.row,
      screenNumber: +room,
      seatGuid: singleSeatData.uuid,
    };

    updateSeat(body);
  }

  return (
    <div className="single-seat-success">
      <div className="single-seat-success__mapper-wrapper">
        <Icon name="Success" size={20} />

        <div className="single-seat-success__mapper-wrapper__items">
          {actions.map(({ href, onClick, title }) => {
            if (href) {
              return (
                <Button href={href} key={title} theme="selection">
                  {title}
                </Button>
              );
            }

            return (
              <Button key={title} onClick={onClick} theme="selection">
                {title}
              </Button>
            );
          })}
        </div>
      </div>

      <div className="single-seat-success__actions">
        <Button onClick={() => navigate(-1)} theme="ghost">
          {t('SHARED.BUTTONS.CANCEL')}
        </Button>
        <Button disabled={hasNotEnoughSeatData} onClick={confirmScannedData}>
          {t('SHARED.BUTTONS.CONFIRM')}
        </Button>
      </div>
    </div>
  );
};

export default SingleSeatSuccess;
