import { FC } from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

import { useToggle } from '../../_hooks';
import { Splash } from '../../_shared';

import ModalWrapper from './_components/modal/modalWrapper/ModalWrapper';
import { SelectionContextProvider } from './_context/SelectionContext';
import { ScanningContextProvider } from './_context/scanning/ScanningContext';
import ScanningRoutes from './scanning/ScanningRoutes';
import SelectionRoutes from './selection/SelectionRoutes';

const AdminRoutes: FC = () => {
  const { t } = useTranslation();
  const [isFirstVisitAdmin, toggleIsFirstVisitAdmin] = useToggle(sessionStorage.getItem('firstVisitAdmin') !== 'true');

  if (isFirstVisitAdmin)
    return (
      <Splash
        storageName="firstVisitAdmin"
        title={
          <>
            <h2>{t('ADMIN.SPLASH.QR')}</h2>
            <h2>{t('ADMIN.SPLASH.MAPPING')}</h2>
          </>
        }
        toggleVisit={toggleIsFirstVisitAdmin}
      />
    );

  return (
    <>
      <Helmet>
        <title>{`${t('SHARED.KINEPOLIS')} | ${t('ADMIN.TITLE')}`}</title>
      </Helmet>
      <SelectionContextProvider>
        <ScanningContextProvider>
          <Routes>
            <Route element={<SelectionRoutes />} path="*" />
            <Route element={<ScanningRoutes />} path="scanning/*" />
          </Routes>
          <ModalWrapper />
        </ScanningContextProvider>
      </SelectionContextProvider>
    </>
  );
};
export default AdminRoutes;
