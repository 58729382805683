import { FC } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button, Icon } from '../../../../_shared';
import { BottomBar, ModalOpener } from '../../_components';
import { useSelectionContext } from '../../_context/SelectionContext';

import './selection.scss';

export const CHEVRON_SIZE = 0.8;

type TOption = {
  disabled?: boolean;
  onClick?: () => void;
  path?: string;
  selectionTitle: string;
  title: string;
};

const Selection: FC = () => {
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const { theatreData, room, scanningMode, pickRoom } = useSelectionContext();
  const notEnoughScanningInfo = !theatreData || !room || !scanningMode;
  const scanPath = scanningMode === 'SINGLE_SEAT' ? 'scanning/single-seat' : 'scanning/room-setup';

  const selectionOptions: TOption[] = [
    {
      path: `${pathname}/theatre-selection`,
      selectionTitle: theatreData?.theatre || i18n.t('ADMIN.SELECTION.MAKE_CHOICE'),
      title: i18n.t('ADMIN.SELECTION.THEATRE'),
    },
    {
      disabled: !theatreData,
      onClick: () =>
        ModalOpener.instance.open({ render: () => <BottomBar label={i18n.t('ADMIN.ROOM.TITLE')} onSubmit={pickRoom} /> }),
      selectionTitle: room || i18n.t('ADMIN.SELECTION.MAKE_CHOICE'),
      title: i18n.t('ADMIN.SELECTION.ROOM'),
    },
    {
      path: `${pathname}/scanning-mode`,
      selectionTitle: scanningMode ? i18n.t(`ADMIN.SCANNING_MODE.${scanningMode}_SETUP`) : i18n.t('ADMIN.SELECTION.MAKE_CHOICE'),
      title: i18n.t('ADMIN.SELECTION.SCANNING_MODE'),
    },
  ];

  const renderOptions = ({ disabled = false, path, onClick, selectionTitle, title }: TOption) => {
    if (path) {
      return (
        <Button
          className={classnames('selection-container', { 'has-value': selectionTitle !== i18n.t('ADMIN.SELECTION.MAKE_CHOICE') })}
          disabled={disabled}
          href={path}
          key={title}
          theme="ghost"
        >
          <div className="selection-container__info">
            <span>{title}</span>
            <span>{selectionTitle}</span>
          </div>
          <Icon name="Chevron" size={CHEVRON_SIZE} />
        </Button>
      );
    }

    return (
      <Button
        className={classnames('selection-container', { 'has-value': selectionTitle !== i18n.t('ADMIN.SELECTION.MAKE_CHOICE') })}
        disabled={disabled}
        key={title}
        onClick={onClick}
        theme="ghost"
      >
        <div className="selection-container__info">
          <span>{title}</span>
          <span>{selectionTitle}</span>
        </div>
        <Icon name="Chevron" size={0.8} />
      </Button>
    );
  };

  return (
    <div className="selection">
      <h1>{i18n.t('ADMIN.SELECTION.TITLE')}</h1>
      {selectionOptions.map(options => renderOptions(options))}
      <Button disabled={notEnoughScanningInfo} href={scanPath}>
        {i18n.t('ADMIN.SELECTION.SCAN')}
      </Button>
    </div>
  );
};

export default Selection;
