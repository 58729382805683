import { FC, useState, useEffect } from 'react';

import { useId } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

import { TIconType } from '../../../../_shared/icon/Icon';
import { Carousel } from '../../_components/carousel/Carousel';
import CarouselCard from '../../_components/carousel/CarouselCard';
import { useSeatInfoContext } from '../../_context/SeatInfoContext';
import Benefits from '../benefits/Benefits';
import UpcomingMovies from '../upcomingMovies/UpcomingMovies';

import TrailerVoting from './../trailerVoting/TrailerVoting';

import './home.scss';

const Home: FC = () => {
  const { t } = useTranslation();
  const [activeTile, setActiveTile] = useState<number>(parseInt(sessionStorage.getItem('activeTile')) || 1);
  const { seatInfo } = useSeatInfoContext();

  const content = [
    <Benefits key={useId()} />,
    <TrailerVoting key={useId()} selectUpcomingMoviesCarouselCard={() => setActiveTile(2)} />,
    <UpcomingMovies key={useId()} />,
  ];

  const defaultCards = [
    <CarouselCard
      activeTile={activeTile == 0}
      icon="LogoStar"
      iconSize={3}
      key={useId()}
      label={t('HOME.MY_KINEPOLIS_BENEFITS')}
      setActiveTile={() => setActiveTile(0)}
    />,
    <CarouselCard
      activeTile={activeTile == 1}
      icon="MovieNow"
      iconSize={3}
      key={useId()}
      label={t('HOME.TRAILER_VOTING')}
      setActiveTile={() => setActiveTile(1)}
    />,
    <CarouselCard
      activeTile={activeTile == 2}
      icon="Calendar"
      iconSize={3}
      key={useId()}
      label={t('HOME.UPCOMING_MOVIES')}
      setActiveTile={() => setActiveTile(2)}
    />,
  ];

  const defaultCardOffset = defaultCards.length;

  const extraCards = seatInfo?.extraUrls
    ?.sort((a, b) => a.sequence - b.sequence)
    .map(({ description, url, urlType }, idx) => {
      const icon = (): TIconType => {
        if (urlType === 'InSeatDelivery') return 'Inseat';
        else if (urlType === 'Quiz') return 'Quiz';
        else if (urlType === 'MovieClub') return 'MovieClub';
        return 'LogoStar';
      };

      const iconSize = urlType === 'MovieClub' ? 5 : 3;

      return (
        <CarouselCard
          activeTile={activeTile == idx + defaultCardOffset}
          href={url}
          icon={icon()}
          iconSize={iconSize}
          key={description}
          label={description}
          setActiveTile={() => setActiveTile(idx + defaultCardOffset)}
        />
      );
    });

  useEffect(() => {
    if (activeTile < content.length) sessionStorage.setItem('activeTile', activeTile.toString());
  }, [activeTile, setActiveTile]);

  return (
    <>
      <main>
        <Carousel activeTile={activeTile} carouselCards={[...defaultCards, ...(extraCards || [])]} />
        {content[activeTile]}
      </main>
    </>
  );
};

export default Home;
