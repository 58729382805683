import React, { useEffect, useState } from 'react';

import './scrollToTop.scss';

type ScrollToTopProps = {
  alwaysVisible?: boolean;
  position?: 'left' | 'right';
};

const ScrollToTop: React.FC<ScrollToTopProps> = ({ alwaysVisible = false, position = 'right' }) => {
  const [isVisible, setIsVisible] = useState(alwaysVisible);

  const scrollUp = () => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  };

  useEffect(() => {
    if (alwaysVisible == false) {
      const toggleVisibility = () => {
        if (window.scrollY > 300) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };

      window.addEventListener('scroll', toggleVisibility);
      return () => window.removeEventListener('scroll', toggleVisibility);
    }
  }, []);

  return (
    isVisible && (
      <button className={`scroll-to-top-button ${position}`} onClick={scrollUp}>
        {'↑'}
      </button>
    )
  );
};

export default ScrollToTop;
