import { FC } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Spinner } from '../../../../_shared';
import BackButton from '../../_components/backButton/BackButton';
import { useSelectionContext } from '../../_context/SelectionContext';
import { useGetComplexes } from '../_queries';

import '../../_styles/_selection.scss';

const TheatreSelection: FC = () => {
  const { data: theatres, isLoading: isTheatresLoading } = useGetComplexes();
  const { t } = useTranslation();
  const { pickTheatre, theatreData: selectedTheatre } = useSelectionContext();

  return (
    <>
      <BackButton title={t('ADMIN.THEATRE.TITLE')} />
      <div className="selection">
        {isTheatresLoading && <Spinner />}
        {theatres?.map(({ name, complexCode }) => {
          const isSelected = selectedTheatre?.theatre === name;
          return (
            <div className={classnames('selection__wrapper', { 'is-selected': isSelected })} key={complexCode}>
              <Button onClick={() => pickTheatre({ complexCode, theatre: name })} theme="ghost">
                {name}
              </Button>
              {isSelected && <Icon name="Check" />}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TheatreSelection;
