import { FC } from 'react';

import classnames from 'classnames';

import { Button, Icon } from '../../../../../_shared';

// TypeChanger Component
type TProps = {
  className: string;
  disabled: boolean;
  onClick: () => void;
};

const TypeChanger: FC<TProps> = ({ className, disabled, onClick }) => {
  return (
    <Button className={classnames('seat-type-changer', className)} disabled={disabled} onClick={onClick} theme="ghost">
      <Icon name="Chevron" size={1} />
    </Button>
  );
};

export default TypeChanger;
