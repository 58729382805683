import { FC, ReactNode, useEffect, useState } from 'react';

import classnames from 'classnames';

import { useToggle } from '../../../../../_hooks';
import { Button } from '../../../../../_shared';
import { ModalOpener } from '../modalOpener/ModalOpener';

import './modalWrapper.scss';

const ModalWrapper: FC = () => {
  const [isVisible, setIsVisible] = useToggle(false);
  const [children, setChildren] = useState<ReactNode>(null);

  useEffect(() => {
    ModalOpener.initialize((render: () => ReactNode) => setChildren(render()));
  }, []);

  useEffect(() => {
    setIsVisible(!!children);
    // Disable & hide the page scroll when a modal is open
    document.body.style.overflow = children ? 'hidden' : 'auto';
  }, [children]);

  return (
    <div className={classnames('modal-wrapper', { 'is-visible': isVisible })}>
      <Button className="modal-wrapper__close" onClick={() => ModalOpener.instance.close()} theme="ghost" />
      {children}
    </div>
  );
};

export default ModalWrapper;
