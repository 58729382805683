import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

import { Button } from '../../../../_shared';
import { ModalOpener } from '../../_components';
import QRScanner from '../../_components/qrScanner/QrScanner';
import { useSelectionContext } from '../../_context/SelectionContext';
import { useScanningContext } from '../../_context/scanning/ScanningContext';
import { SCANNING_ACTIONS } from '../../_context/scanning/actions';
import { extractUuid } from '../../_utils/scannerHelper';
import { CHEVRON_SIZE } from '../../selection/selection/Selection';

import './singleSeat.scss';

const SingleSeat: FC = () => {
  const { t } = useTranslation();
  const { theatreData, room } = useSelectionContext();
  const { setSeatData } = useScanningContext();
  const navigate = useNavigate();
  const noTheatreInfo = !!theatreData || !!room;

  if (noTheatreInfo) <Navigate to="/admin" />;

  function onFind(value: string): void {
    if (value) {
      setSeatData({ payload: { uuid: extractUuid(value) }, type: SCANNING_ACTIONS.UPDATE_SINGLE_SEAT_DATA });
      ModalOpener.instance.close();
      navigate('success');
    }
  }

  return (
    <div className="single-seat">
      <QRScanner invertColors={false} onFind={onFind} />

      <div className="single-seat__info-box">
        <Button href="/admin" icon="Chevron" iconSize={CHEVRON_SIZE} theme="ghost" />
        {theatreData.theatre && (
          <div className="single-seat__info-box__item">
            <span>{t('ADMIN.SELECTION.THEATRE')}</span>
            <span>{theatreData.theatre}</span>
          </div>
        )}
        {room && (
          <div className="single-seat__info-box__item">
            <span>{t('ADMIN.SELECTION.ROOM')}</span>
            <span>{room}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleSeat;
