import { env } from '../../../env';

import { isNumeric } from './numberHelpers';

export const VOTE_BEFORE_INTERVAL = isNumeric(env.REACT_APP_VOTE_BEFORE_INTERVAL_IN_MINUTES)
  ? Number(env.REACT_APP_VOTE_AFTER_INTERVAL_IN_MINUTES)
  : 15;
export const VOTE_AFTER_INTERVAL = isNumeric(env.REACT_APP_VOTE_AFTER_INTERVAL_IN_MINUTES)
  ? Number(env.REACT_APP_VOTE_AFTER_INTERVAL_IN_MINUTES)
  : 20;
