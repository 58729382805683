import { FC } from 'react';

import { ToastContainer } from 'react-toastify';

import { Icon } from './_shared';

import 'react-toastify/dist/ReactToastify.css';
import './toastify.scss';

const Toastify: FC = () => (
  <ToastContainer
    bodyClassName="toast-body"
    className="toast-container"
    closeButton={null}
    closeOnClick={false}
    icon={<Icon name="ToastCheck" />}
    position="bottom-right"
    progressClassName="toast-progress"
    toastClassName="toast"
  />
);

export default Toastify;
