import { FC } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Icon } from '../../../../../_shared';
import BackButton from '../../../_components/backButton/BackButton';
import { SeatType, useScanningContext } from '../../../_context/scanning/ScanningContext';
import { SCANNING_ACTIONS } from '../../../_context/scanning/actions';

import '../../../_styles/_selection.scss';

const SingleSeatSeatType: FC = () => {
  const { t } = useTranslation();
  const { singleSeatData, setSeatData } = useScanningContext();
  const navigate = useNavigate();

  function confirmSeatType(seatType: string) {
    setSeatData({ payload: { seatType }, type: SCANNING_ACTIONS.UPDATE_SINGLE_SEAT_DATA });
    navigate(-1);
  }

  return (
    <>
      <BackButton title={t('ADMIN.THEATRE.TITLE')} />
      <div className="selection">
        {Object.values(SeatType).map(type => {
          const isSelected = type === singleSeatData.seatType;
          return (
            <div className={classnames('selection__wrapper', { 'is-selected': isSelected })} key={type}>
              <Button onClick={() => confirmSeatType(type)} theme="ghost">
                {type}
              </Button>
              {isSelected && <Icon name="Check" />}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SingleSeatSeatType;
