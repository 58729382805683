import { FC } from 'react';

import { useOidc } from '@axa-fr/react-oidc';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button } from '..';

import './header.scss';

const Header: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { logout, isAuthenticated } = useOidc();
  const isMinimal = ['/languages', '/guest'].includes(pathname);
  const isAdmin = pathname.includes('admin');

  const renderAuthButton = () => {
    if (!isAdmin) {
      if (isAuthenticated)
        return (
          <Button onClick={() => logout()} theme="ghost">
            {t('SHARED.BUTTONS.LOGOUT')}
          </Button>
        );
    }
  };

  if (!isMinimal && ['/benefits', '/trailer-voting', '/settings'].includes(pathname)) {
    return (
      <header className="header header--modal">
        <Button hideLabel href="/" icon="Close" iconSize={2.4} theme="ghost">
          {t('SHARED.BUTTONS.CLOSE')}
        </Button>
      </header>
    );
  }

  return (
    <header className={classNames('header', { 'header--minimal': isMinimal && !isAuthenticated })}>
      {!isMinimal && !isAdmin && (
        <Button hideLabel href="settings" icon="Settings" iconSize={2.4} theme="ghost">
          {t('SETTINGS.TITLE')}
        </Button>
      )}
      <Button
        className={classNames('logo', { 'is-admin': isAdmin })}
        hideLabel
        href="/"
        icon={isAdmin ? 'LogoStar' : 'Logo'}
        iconHeight={isAdmin ? 3 : 2.4}
        iconSize={isAdmin ? 3 : 12.5}
        theme="ghost"
      >
        {t('SPLASH.TITLE')}
      </Button>

      {renderAuthButton()}
    </header>
  );
};

export default Header;
