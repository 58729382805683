import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Icon } from '../../../../_shared';

import './settings.scss';

const Settings: FC = () => {
  const { t } = useTranslation();

  return (
    <main className="settings">
      <Button href="../languages" theme="wrapper">
        <span>{t('SETTINGS.LANGUAGE_PICKER')}</span>
        <Icon name="Right" size={2.4} />
      </Button>
    </main>
  );
};

export default Settings;
