import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast, ToastOptions } from 'react-toastify';

import { HttpClient, TApiError } from '../../../_http';
import { useSeatInfoContext } from '../_context/SeatInfoContext';
import { TVoteBody } from '../_models';

async function vote(body: TVoteBody): Promise<void> {
  return HttpClient.post<void>('Vote', body);
}

export function useVote() {
  const { t } = useTranslation();
  const { movieInfo } = useSeatInfoContext();
  const toastPositioner = { position: 'top-center', theme: 'colored' } as ToastOptions;

  return useMutation<void, TApiError, TVoteBody>('vote', vote, {
    onError() {
      toast.error('Something went wrong'), toastPositioner;
    },
    onSuccess: (_, body) => {
      const isLiked = body.like;
      toast.success(
        t(isLiked ? 'TRAILER_VOTING.VOTE_SUCCESS' : 'TRAILER_VOTING.REMOVE_SUCCESS', {
          title: movieInfo.trailers.find(trailer => trailer?.movieId === body?.trailerMovieId)?.movieTitle,
        }),
        toastPositioner,
      );
    },
  });
}
