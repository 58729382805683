import { useQuery } from 'react-query';

import { HttpClient } from '../../../../../_http';
import { env } from '../../../../../env';

async function getUpComingMovies(): Promise<TUpcomingMoviesResponse> {
  const response = await HttpClient.get<TUpcomingMoviesResponse>(
    `${env?.REACT_APP_UPCOMING_MOVIES_API_HOST}FutureReleases/BE/NL/KinepolisBelgium/WWW`,
  );

  const filteredMovies = {
    ...response,
    films: response?.films
      ?.map(film => ({
        ...film,
        images: film?.images?.filter(image => image?.mediaType == 'Poster Graphic'),
      }))
      .filter(movie => !movie.event?.isActive)
      .sort((a, b) => a?.releaseDate?.localeCompare(b?.releaseDate)),
  };
  return filteredMovies;
}

export function useGetUpComingMovies() {
  return useQuery(['get-upcoming-movies'], () => getUpComingMovies(), {});
}

export type TUpcomingMoviesResponse = {
  films: TFilmResponse[];
};

export type TFilmResponse = {
  attributes: {
    code: string;
    id: string;
    imageUrl: string;
    isActive: boolean;
    name: string;
    promoted: boolean;
  }[];
  audioLanguage: string;
  censor: {
    iconUrl: string;
    id: string;
    isActive: boolean;
    minimumAge: number;
    name: string;
  };
  corporateId: number;
  country: string;
  countryOfOrigin: {
    code: string;
    id: string;
    name: string;
  };
  distributorName: string;
  documentType: string;
  duration: number;
  event: {
    code: string;
    description: string;
    id: string;
    isActive: boolean;
    name: string;
    shortName: string;
  };
  filmPersons: {
    firstName: string;
    id: string;
    lastName: string;
    role: string;
    selligentId: string;
  }[];
  genres: {
    id: number;
    name: string;
    selligentId: string;
    shortName: string;
  }[];
  hasSessions: boolean;
  id: string;
  images: {
    lastModificationDate: string;
    mediaClass: string;
    mediaType: string;
    url: string;
  }[];
  imdbCode: string;
  language: string;
  name: string;
  originalAudioLanguage: string;
  releaseDate: string;
  releaseType: string;
  shortSynopsis: string;
  showAsFutureRelease: boolean;
  sortingNumber: number;
  spokenLanguage: {
    code: string;
    id: string;
    name: string;
  };
  subtitles: {
    code: string;
    id: string;
    name: string;
  }[];
  synopsis: string;
  title: string;
  trailers: {
    lastModificationDate: string;
    mediaClass: string;
    mediaType: string;
    url: string;
  }[];
};
