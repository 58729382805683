import { FC } from 'react';

import { OidcProvider } from '@axa-fr/react-oidc';
import { Route, Routes } from 'react-router-dom';

import Toastify from './Toastify';
import { CallBack, Header, Spinner } from './_shared';
import { selectLanguageAndCountry } from './_utils/languageHelper';
import { Config } from './config';
import AdminRoutes from './modules/admin/AdminRoutes';
import AppRoutes from './modules/app/AppRoutes';
import useGoogleTagManager from './modules/app/_gooleAnalytics/useGoogleTagManager';

const isAdmin = window.location.pathname.includes('admin');
const { isoCountryCode, possibleLanguage } = selectLanguageAndCountry();
const oidcConfiguration = Config.getOidcConfiguration;
const customOidcConfig = {
  country: isoCountryCode,
  language: possibleLanguage,
  sourceid: '207',
};

const App: FC = () => {
  useGoogleTagManager();
  return (
    <OidcProvider
      authenticatingComponent={() => <Spinner overlay />}
      callbackSuccessComponent={() => <Spinner overlay />}
      configuration={
        isAdmin
          ? Config.getAdminOidcConfiguration
          : {
              ...oidcConfiguration,
              authority_configuration: {
                authorization_endpoint: `${oidcConfiguration.authority}/connect/authorize`,
                end_session_endpoint: `${oidcConfiguration.authority}/connect/endsession`,
                issuer: oidcConfiguration.authority,
                revocation_endpoint: `${oidcConfiguration.authority}/connect/revocation`,
                token_endpoint: `${oidcConfiguration.authority}/connect/token`,
                userinfo_endpoint: `${oidcConfiguration.authority}/connect/userinfo`,
              },
              extras: customOidcConfig,
            }
      }
      loadingComponent={() => <Spinner overlay />}
    >
      <Header />
      <Routes>
        <Route element={<AdminRoutes />} path="/admin/*" />
        <Route element={<AppRoutes />} path="/*" />

        <Route element={CallBack} path="/authentication/callback" />
        <Route element={CallBack} path="/authentication/silent_callback" />
      </Routes>
      <Toastify />
    </OidcProvider>
  );
};

export default App;
