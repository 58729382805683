// Ugly I know... but using a library doesn't do the trick to scan an inverted QR code.

import React, { Component } from 'react';

import jsQR from 'jsqr';

import { Icon } from '../../../../_shared';

import './qrScanner.scss';

class QRScanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notEnabled: true,
      loading: true,
      video: null,
    };

    this.handleScan = this.handleScan.bind(this);
  }

  componentDidMount() {
    const video = document.createElement('video');

    this.setState({ video });

    navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } }).then(stream => {
      video.srcObject = stream;
      video.setAttribute('playsinline', true);
      video.play();
      requestAnimationFrame(this.handleScan);
    });
  }

  componentWillUnmount() {
    this.state.video.pause();
  }

  handleScan() {
    const { notEnabled, video, loading } = this.state;
    const { pauseScanning, invertColors, onFind } = this.props;
    const canvasElement = document.getElementById('qrCanvas');
    const canvas = canvasElement.getContext('2d');

    if (!pauseScanning) {
      if (notEnabled) this.setState({ notEnabled: false });
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        if (loading) this.setState({ loading: false });
        canvasElement.height = video.videoHeight;
        canvasElement.width = video.videoWidth;
        canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
        var imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
        var dataArr = imageData.data;

        // Invert colors (dark background, white QR)
        if (!invertColors) {
          for (var i = 0; i < dataArr.length; i += 4) {
            var r = dataArr[i]; // Red color lies between 0 and 255
            var g = dataArr[i + 1]; // Green color lies between 0 and 255
            var b = dataArr[i + 2]; // Blue color lies between 0 and 255

            var invertedRed = 255 - r;
            var invertedGreen = 255 - g;
            var invertedBlue = 255 - b;

            dataArr[i] = invertedRed;
            dataArr[i + 1] = invertedGreen;
            dataArr[i + 2] = invertedBlue;
          }
        }

        var code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: 'invertOnly',
        });

        if (code) {
          onFind(code.data);
        }
      }
    }

    requestAnimationFrame(this.handleScan);
  }

  viewFinder() {
    return <Icon className="qr-border" name="QrBorder" size={27} />;
  }

  render() {
    return (
      <div className="qr-scanner">
        <canvas id="qrCanvas" />
        {this.viewFinder()}
      </div>
    );
  }
}

export default QRScanner;
