// Fallback language is english

export enum languages {
  Catalan = 'ca-ES',
  DutchBe = 'nl-BE',
  DutchNl = 'nl-NL',
  English = 'en',
  FrenchBe = 'fr-BE',
  FrenchFr = 'fr-FR',
  FrenchLu = 'fr-LU',
  German = 'de-CH',
  Spanish = 'es-ES',
}

export function labelForLocale(
  locale: languages,
):
  | 'SHARED.LOCALE.CA'
  | 'SHARED.LOCALE.DU_BE'
  | 'SHARED.LOCALE.DU_NL'
  | 'SHARED.LOCALE.FR_BE'
  | 'SHARED.LOCALE.FR_FR'
  | 'SHARED.LOCALE.FR_LU'
  | 'SHARED.LOCALE.GE'
  | 'SHARED.LOCALE.ES'
  | 'SHARED.LOCALE.EN' {
  switch (locale) {
    case languages.Catalan:
      return 'SHARED.LOCALE.CA';
    case languages.DutchBe:
      return 'SHARED.LOCALE.DU_BE';
    case languages.DutchNl:
      return 'SHARED.LOCALE.DU_NL';
    case languages.FrenchBe:
      return 'SHARED.LOCALE.FR_BE';
    case languages.FrenchFr:
      return 'SHARED.LOCALE.FR_FR';
    case languages.FrenchLu:
      return 'SHARED.LOCALE.FR_LU';
    case languages.German:
      return 'SHARED.LOCALE.GE';
    case languages.Spanish:
      return 'SHARED.LOCALE.ES';
    default:
      return 'SHARED.LOCALE.EN';
  }
}

export function getLocale(locale: string): languages {
  switch (locale) {
    case languages.Catalan:
      return languages.Catalan;
    case languages.DutchBe:
      return languages.DutchBe;
    case languages.DutchNl:
      return languages.DutchNl;
    case languages.FrenchBe:
      return languages.FrenchBe;
    case languages.FrenchFr:
      return languages.FrenchFr;
    case languages.FrenchLu:
      return languages.FrenchLu;
    case languages.German:
      return languages.German;
    case languages.Spanish:
      return languages.Spanish;
    default:
      return languages.English;
  }
}
