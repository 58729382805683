import { TDataLayerObject } from './modules/app/_gooleAnalytics/datalayer';

declare global {
  interface Window {
    //Used for Google analytics
    dataLayer: TDataLayerObject[];
    env: Record<keyof EnvType, string>;
  }
}

type EnvType = {
  NODE_ENV: string;
  REACT_APP_ADMIN_API_HOST: string;
  REACT_APP_AD_CONNECTIVE: string;
  REACT_APP_API_HOST: string;
  REACT_APP_AUTHORITY: string;
  REACT_APP_BRAND_NAME: string;
  REACT_APP_CLIENT_ID: string;
  REACT_APP_COOKIE_CONSENT: string;
  REACT_APP_DEV_SKIP: string;
  REACT_APP_POSTER_CDN: string;
  REACT_APP_SCOPE: string;
  REACT_APP_SENTRY_DSN: string;
  REACT_APP_UPCOMING_MOVIES_API_HOST: string;
  REACT_APP_VOTE_AFTER_INTERVAL_IN_MINUTES: string;
  REACT_APP_VOTE_BEFORE_INTERVAL_IN_MINUTES: string;
};

export const env: EnvType = { ...process.env, ...window.env };
