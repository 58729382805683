import { DefaultOptions as ReactQueryOptions, QueryClient } from 'react-query';

import { HttpStatus, TApiError } from './_http';
import { getBaseUrl } from './_utils/linkHelpers';
import { env } from './env';

export type TEnvironment = 'local' | 'development' | 'test' | 'staging' | 'production';

const nodeEnv = env.NODE_ENV;
function assertNodeEnv(env: string | undefined): asserts env {
  if (!env) {
    throw Error('NODE ENV must be specified');
  }
}

assertNodeEnv(nodeEnv);

export class Config {
  static get api(): { adminHost: string; host: string } {
    return {
      adminHost: env.REACT_APP_ADMIN_API_HOST,
      host: env.REACT_APP_API_HOST,
    };
  }

  static get environment(): TEnvironment {
    return nodeEnv as TEnvironment;
  }

  static get sentryDsn(): string {
    return env.REACT_APP_SENTRY_DSN as string;
  }

  static get reactQuery(): ReactQueryOptions {
    const handleUnauthorizedCalls = (error: TApiError) => {
      if (error.statusCode === HttpStatus.Unauthorized && !window.location.href.includes('/auth')) {
        // do authenticate call again, redirect to login onError
        new QueryClient().invalidateQueries('authenticate');
      }
    };
    return {
      mutations: {
        onError: handleUnauthorizedCalls,
        retry: false,
      },
      queries: {
        onError: handleUnauthorizedCalls,
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 1000 * 60, // 1 minute
      },
    };
  }

  static get getOidcConfiguration() {
    const base = getBaseUrl();

    return {
      authority: env.REACT_APP_AUTHORITY,
      automaticSilentRenew: false,
      client_id: env.REACT_APP_CLIENT_ID,
      loadUserInfo: true,
      monitorSession: false,
      post_logout_redirect_uri: base,
      redirect_uri: base + '/authentication/callback',
      response_type: 'code',
      scope: env.REACT_APP_SCOPE,
      silent_redirect_uri: base + '/authentication/silent_callback',
    };
  }

  static get getAdminOidcConfiguration() {
    return {
      ...this.getOidcConfiguration,
      scope: env.REACT_APP_SCOPE,
    };
  }
}
