import { FC } from 'react';

import { useOidc } from '@axa-fr/react-oidc';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '../../../../_shared';

import './benefits.scss';

const Benefits: FC = () => {
  const { t } = useTranslation();
  const { login, isAuthenticated } = useOidc();

  const benefits = [
    t('BENEFITS.BENEFIT_1'),
    t('BENEFITS.BENEFIT_2'),
    t('BENEFITS.BENEFIT_3'),
    t('BENEFITS.BENEFIT_4'),
    t('BENEFITS.BENEFIT_5'),
  ];

  return (
    <main className="benefits">
      <h1>{t('BENEFITS.TITLE')}</h1>
      <p>{t('BENEFITS.TEXT')}</p>
      <ul>
        {benefits.map(benefit => (
          <li key={benefit}>
            <Icon name="Check" size={2.4} />
            <span>{benefit}</span>
          </li>
        ))}
      </ul>
      <Button href={t('BENEFITS.URL')} theme="ghost">
        {t('BENEFITS.SHOW_ALL')}
      </Button>
      {!isAuthenticated && <Button onClick={() => login()}>{t('BENEFITS.REGISTER')}</Button>}
    </main>
  );
};

export default Benefits;
