import i18n from '../_translations/i18n';

export function trim(value: string): string {
  return value.replace(/\s/g, '');
}

export function isEmptyString(value: string): boolean {
  return !value || trim(value) === '';
}

export type TValidatorResponse = {
  isValid: boolean;
  message?: string;
};

export const formValidator = {
  email: function (email: string): TValidatorResponse {
    const isValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    return {
      isValid,
      message: isValid ? null : i18n.t('ERRORS.VALIDATION.INVALID_EMAIL'),
    };
  },
  maxLength: function (value: string | number, max: number): TValidatorResponse {
    const isValid = typeof value === 'string' ? `${value}`.length <= max : value <= max;
    return {
      isValid,
      message: isValid ? null : i18n.t('ERRORS.VALIDATION.MAX_LENGTH', { length: max }),
    };
  },
  required: function (value: unknown): TValidatorResponse {
    const isValid = !isEmptyString(`${value}`) && !!value;
    return {
      isValid,
      message: isValid ? null : i18n.t('ERRORS.VALIDATION.REQUIRED'),
    };
  },
  rowValidation: function (value: string): TValidatorResponse {
    const isValid = new RegExp('^[A-Za-z0-9]{1,2}$').test(value);
    return {
      isValid,
      message: isValid ? null : i18n.t('ERRORS.VALIDATION.ROW_VALIDATION'),
    };
  },
  seatValidation: function (value: number, max: number): TValidatorResponse {
    const isValid = new RegExp('^[0-9]{1,3}$').test(`${value}`);
    return {
      isValid,
      message: isValid ? null : i18n.t('ERRORS.VALIDATION.MAX_LENGTH', { length: max }),
    };
  },
};
