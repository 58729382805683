export type TDataLayerObject = Record<string, unknown>;

const initializeDataLayer = (): void => {
  // Initialize the dataLayer if it doesn't exist
  window.dataLayer = window?.dataLayer || [];

  // Push the initial event to the dataLayer
  window?.dataLayer?.push({
    event: 'gtm.js',
    'gtm.start': new Date().getTime(),
  });
};

const clearDataLayer = (): void => {
  window.dataLayer = [];
};

// // Internal function to push events to the dataLayer -> events not needed yet
// const pushEventToDataLayer = (dataLayerObject: TDataLayerObject): void => {
//   window?.dataLayer?.push(dataLayerObject);
// };

export { initializeDataLayer, clearDataLayer };
