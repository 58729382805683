import { FC, useEffect } from 'react';

import { useOidcUser } from '@axa-fr/react-oidc';
import { useNavigate } from 'react-router-dom';

import Spinner from '../spinner/Spinner';

const CallBack: FC = () => {
  const { oidcUser } = useOidcUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (oidcUser) {
      navigate('/');
    }
  }, [oidcUser, history]);

  return (
    <div className="empty-screen">
      <Spinner />
    </div>
  );
};

export default CallBack;
