import React, { FC } from 'react';

import Icon, { TIconType } from '../../../../_shared/icon/Icon';

import './carouselCard.scss';

export type TCarouselCardProps = {
  activeTile: boolean;
  href?: string;
  icon?: TIconType;
  iconSize?: number;
  label?: string;
  setActiveTile?: () => void;
};

const CarouselCard: FC<TCarouselCardProps> = ({ activeTile, href, icon, iconSize, label = '', setActiveTile }) => {
  const activeClass = activeTile ? 'active-tile' : '';

  return (
    <div className={`carousel-card ${activeClass}`} onClick={setActiveTile && setActiveTile}>
      <a href={href && href}>
        {icon && (
          <span className="icon">
            <Icon name={icon} size={iconSize} />
          </span>
        )}
        {label && <p className="label">{label}</p>}
      </a>
    </div>
  );
};

export default CarouselCard;
